import React from 'react';
import { navigate, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Layout from '../../components/layout';
import HeroImage from '../../components/hero-image/hero-image';
import JobsSearch from '../../components/jobs-search/jobs-search';
import HomePageJobsList from '../../components/home-page/home-page-jobs-list';
import HomePageAboutSection from '../../components/home-page/home-page-about-section';
import HomePageSlider from '../../components/home-page/slider-and-apply-sections/slider/home-page-slider';
import ApplySection from '../../components/home-page/slider-and-apply-sections/apply-section/apply-section';

import styles from './index.module.scss';

export default ({ data }) => {
  const handleJobSearchSubmit = () => {
    navigate('/jobs');
  };

  const getHomePageImageEdge = name => {
    return data.allFile.edges.find(e => e.node.name === name);
  };

  const ogImageUrlPath =
    getHomePageImageEdge('home-page-og-image').node.childImageSharp.fluid.src;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;

  return (
    <>
      <Helmet>
        <title>Trustaff: High-Paying Travel Nursing Jobs Nationwide</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Since 2002, Trustaff has been a leading force in healthcare staffing. We build lasting relationships with both the talented professionals looking for their next job and the companies that need their skills to succeed, offering the best travel nurse assignments in the country."
        />
        <meta
          property="og:title"
          content="Best Travel Nurse Assignments - Trustaff Travel Nursing Agency"
        />
        <meta
          property="og:description"
          content="Since 2002, Trustaff has been a leading force in healthcare staffing. We build lasting relationships with both the talented professionals looking for their next job and the companies that need their skills to succeed, offering the best travel nurse assignments in the country."
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta property="og:url" content="https://www.trustaff.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <HeroImage
          fluid={getHomePageImageEdge('road').node.childImageSharp.fluid}
          heroOverlayClass={styles.heroOverlay}
        >
          <div className={`columns ${styles.contentWrapper}`}>
            <div className={`column is-8 is-offset-2 ${styles.content}`}>
              <div className={styles.heroTextArea}>
                <h2 className={`title ${styles.subheader} has-text-centered`}>
                  Find your next travel job here
                </h2>
              </div>

              <div className={styles.jobSearchWrapper}>
                <JobsSearch onSubmit={handleJobSearchSubmit} isHorizontal />
              </div>
            </div>
          </div>
        </HeroImage>

        <ApplySection
          fluid={
            getHomePageImageEdge('nurse-with-patient').node.childImageSharp
              .fluid
          }
        />

        <section className="section">
          <HomePageSlider />
        </section>

        <div className="section">
          <HomePageJobsList data={data} />
        </div>
        <div
          className={`section ${styles.sectionBackgroundGrey} ${styles.sectionNoSidePaddingMobile}`}
        >
          <HomePageAboutSection
            fluid={
              getHomePageImageEdge('trustaff-team').node.childImageSharp.fluid
            }
          />
        </div>
      </Layout>
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(filter: { relativePath: { regex: "/^home-page/" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allImageSharp(
      filter: { fluid: { originalName: { regex: "/(state-hero)/" } } }
    ) {
      edges {
        node {
          ... on ImageSharp {
            fluid(maxWidth: 2400) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              originalName
            }
          }
        }
      }
    }
  }
`;
